











import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'

@Component({
  components: {
    ZoomableImage
  }
})
export default class ArticlesListPanel extends Vue {

}
